import Header from "../../../Components/Header";
import "./style.css";
import ava from "./ava.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderDesc from "../../../Components/HeaderDesc";
import Profile from "..";
export default function ProfileData() {
  const [user] = useState(JSON.parse(localStorage.user));

  const Navigate = useNavigate();
  return document.body.clientWidth < 640 ? (
    <div className="profileData">
      <Header></Header>
      <div className="lod">Профиль</div>
      <img src={ava} className="ava" alt="" />
      <p className="dt">{user.Fio}</p>
      <p className="dt">{user.Phone}</p>
      <p className="dt">{user.Email}</p>
      <div
        id="scr"
        className="tas"
        onClick={() => {
          Navigate("/anketa");
        }}
      >
        Моя анкета
      </div>
    </div>
  ) : (
    <div className="profDataDesc">
      <HeaderDesc></HeaderDesc>
      <div className="profDataDescBody">
        <Profile></Profile>
        <div className="profDataRight">
          <div className="lod">Профиль</div>
          <img src={ava} className="ava" alt="" />
          <p className="dt">{user.Fio}</p>
          <p className="dt">{user.Phone}</p>
          <p className="dt">{user.Email}</p>
          <div
            id="scr"
            className="tas"
            onClick={() => {
              Navigate("/anketa");
            }}
          >
            Моя анкета
          </div>
        </div>
      </div>
    </div>
  );
}
