import Header from "../../Components/Header";
import "./style.css";
import arr from "./arr.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import HeaderDesc from "../../Components/HeaderDesc";
import { useState } from "react";
export default function Vebinar() {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  if (document.body.clientWidth < 640) {
    return (
      <div className="vebinar">
        {open && (
          <div
            className="popDop"
            id="cls"
            onClick={(e) => {
              e.target.id == "cls" && setOpen(false);
            }}
          >
            <div className="regPP">
              <form
                id="ltForm3580694"
                className="
  lt-normal-form
  lt-form-inner
  lt-form
            "
                action="https://theta-ok.ru/pl/lite/block-public/process-html?id=2070746217"
                method="post"
                data-open-new-window="0"
              >
                <input type="hidden" name="formParams[setted_offer_id]" />
                <br />
                <input
                  type="text"
                  maxlength="60"
                  placeholder="Введите ваше имя"
                  name="formParams[full_name]"
                  value=""
                />
                <br />
                <input
                  type="text"
                  maxlength="60"
                  placeholder="Введите ваш эл. адрес"
                  name="formParams[email]"
                  value=""
                />
                <br />
                <input
                  type="text"
                  maxlength="60"
                  placeholder="Введите ваш телефон"
                  name="formParams[phone]"
                  value=""
                />
                <br />
                <button
                  type="submit"
                  id="button8388820"
                  onclick="if(window['btnprs66cd98beb5f6b']){return false;}window['btnprs66cd98beb5f6b']=true;setTimeout(function(){window['btnprs66cd98beb5f6b']=false},6000);return true;"
                >
                  ЗАРЕГИСТРИРОВАТЬСЯ{" "}
                </button>
                <br />
                <input
                  type="hidden"
                  id="124494066cd98bea54c3"
                  name="__gc__internal__form__helper"
                  class="__gc__internal__form__helper"
                  value=""
                />
                <input
                  type="hidden"
                  id="124494066cd98bea54c3ref"
                  name="__gc__internal__form__helper_ref"
                  class="__gc__internal__form__helper_ref"
                  value=""
                />
                <input type="hidden" name="requestTime" value="1724750014" />
                <input
                  type="hidden"
                  name="requestSimpleSign"
                  value="8b6e3d717c492a0323d7c05fc08f6922"
                />
                <input type="hidden" name="isHtmlWidget" value="1" />
              </form>
              <span id="gccounterImgContainer"></span>
            </div>
          </div>
        )}
        <Header></Header>
        <div className="vebinarBody">
          <div className="classic">хочешь</div>
          <p>
            научиться правильно расшифровывать карты, хочешь
            <b> понять мир и себя</b> иди к нам
          </p>
          <img src={arr} alt="" />
          <video controls>
            <source src="./video/7d.mp4" type="video/mp4" />
          </video>
          <div
            id="scr"
            className="tas"
            onClick={() => {
              setOpen(true);
            }}
          >
            Регистрация
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="vebinar">
        {open && (
          <div
            className="popDop"
            id="cls"
            onClick={(e) => {
              e.target.id == "cls" && setOpen(false);
            }}
          >
            <div className="regPP">
              <form
                id="ltForm3580694"
                class="
  lt-normal-form
  lt-form-inner
  lt-form
            "
                action="https://theta-ok.ru/pl/lite/block-public/process-html?id=2070746217"
                method="post"
                data-open-new-window="0"
              >
                <input type="hidden" name="formParams[setted_offer_id]" />
                <br />
                <input
                  type="text"
                  maxlength="60"
                  placeholder="Введите ваше имя"
                  name="formParams[full_name]"
                  value=""
                />
                <br />
                <input
                  type="text"
                  maxlength="60"
                  placeholder="Введите ваш эл. адрес"
                  name="formParams[email]"
                  value=""
                />
                <br />
                <input
                  type="text"
                  maxlength="60"
                  placeholder="Введите ваш телефон"
                  name="formParams[phone]"
                  value=""
                />
                <br />
                <button
                  type="submit"
                  id="button8388820"
                  onclick="if(window['btnprs66cd98beb5f6b']){return false;}window['btnprs66cd98beb5f6b']=true;setTimeout(function(){window['btnprs66cd98beb5f6b']=false},6000);return true;"
                >
                  ЗАРЕГИСТРИРОВАТЬСЯ{" "}
                </button>
                <br />
                <input
                  type="hidden"
                  id="124494066cd98bea54c3"
                  name="__gc__internal__form__helper"
                  class="__gc__internal__form__helper"
                  value=""
                />
                <input
                  type="hidden"
                  id="124494066cd98bea54c3ref"
                  name="__gc__internal__form__helper_ref"
                  class="__gc__internal__form__helper_ref"
                  value=""
                />
                <input type="hidden" name="requestTime" value="1724750014" />
                <input
                  type="hidden"
                  name="requestSimpleSign"
                  value="8b6e3d717c492a0323d7c05fc08f6922"
                />
                <input type="hidden" name="isHtmlWidget" value="1" />
              </form>
              <span id="gccounterImgContainer"></span>
            </div>
          </div>
        )}
        {document.body.clientWidth < 640 ? (
          <Header></Header>
        ) : (
          <HeaderDesc></HeaderDesc>
        )}
        <div className="vebinarBody">
          <div className="classic">хочешь</div>
          <p>
            научиться правильно расшифровывать карты, хочешь
            <b> понять мир и себя</b> иди к нам
          </p>
          <img src={arr} alt="" />
          <h4>БЕСПЛАТНЫЙ 7 дневный курс</h4>
          <video controls>
            {" "}
            <source src="./video/7d.mp4" type="video/mp4" />
          </video>
          <div
            id="scr"
            className="tas"
            onClick={() => {
              setOpen(true);
            }}
          >
            Зарегистрироваться
          </div>
        </div>
      </div>
    );
  }
}
