import { useNavigate } from "react-router-dom";
import Header from "../../../Components/Header";
import { useEffect, useState } from "react";
import { getCourses } from "../../../Api";
import { routes } from "../../../routes";

export default function CurseEm() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  const [items, setItems] = useState({});
  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();
  console.log(items);
  useEffect(() => {
    getCourses().then((data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].ID == myParam) {
          setItems(data[i]);
        }
      }
    });
  }, []);
  return (
    <div>
      {open && (
        <div
          className="popDop"
          id="cls"
          onClick={(e) => {
            e.target.id == "cls" && setOpen(false);
          }}
        >
          {" "}
          <div
            className="regPP"
            dangerouslySetInnerHTML={{
              __html: items && items.Frame,
            }}
          ></div>
        </div>
      )}
      <Header></Header>
      <div className="descCurses">
        {items && items.Img && (
          <div dangerouslySetInnerHTML={{ __html: items.Img }}></div>
        )}

        {items && items.Title && <h3>{items.Title}</h3>}
        {items &&
          items.DescCard &&
          items.DescCard.split("#").map((em) => {
            return (
              <div className="descCurseEm">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10"
                    cy="10"
                    r="8"
                    stroke="#FFDB83"
                    stroke-width="2"
                  />
                  <path
                    d="M8 10L9.5 11.5L13 8"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>{em}</p>
              </div>
            );
          })}
        <div className="priceEm">Стоимость — {items.Price} тыс. руб.</div>
        <div className="tas" onClick={() => setOpen(true)}>
          Приобрести
        </div>
      </div>
    </div>
  );
}
