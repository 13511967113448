import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { getDop } from "../../Api";
import gal from "./gal.png";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { Zoom } from "react-reveal";
import HeaderDesc from "../../Components/HeaderDesc";
import YouTube from "react-youtube";
export default function DopMaterials() {
  const [materials, setMaterials] = useState([]);
  const [currentCurse, setCurrentCurse] = useState();
  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();
  useEffect(() => {
    getDop().then((data) => {
      setMaterials(data);
    });
  }, []);
  useEffect(() => {
    if (materials.length > 0) {
      setCurrentCurse(materials[0]);
    }
  }, [materials]);

  return document.body.clientWidth < 640 ? (
    <div className="Dop">
      <Header></Header>
      <h3>Бонусные материалы</h3>
      {materials.length > 0 &&
        materials.map((em, index) => {
          return (
            <Zoom left>
              <div
                className="matEm"
                key={"material" + index}
                onClick={() => Navigate(routes.dopEm + "?key=" + index)}
              >
                <img src={gal} alt="" />
                <p>{em.Title}</p>
              </div>
            </Zoom>
          );
        })}
    </div>
  ) : (
    <div className="dopDesc">
      <HeaderDesc></HeaderDesc>
      {open && (
        <div
          className="popDop"
          id="cls"
          onClick={(e) => {
            e.target.id == "cls" && setOpen(false);
          }}
        >
          {" "}
          <div
            className="regPP"
            dangerouslySetInnerHTML={{
              __html: currentCurse && currentCurse.Frame,
            }}
          ></div>
        </div>
      )}
      <div className="dopDescBody">
        <div className="dopdescbodyleft">
          {materials.length > 0 &&
            materials.map((em, index) => {
              return (
                <Zoom left>
                  <div
                    className="matEm"
                    key={"material" + index}
                    onClick={() => {
                      setCurrentCurse(em);
                    }}
                  >
                    <img src={gal} alt="" />
                    <p>{em.Title}</p>
                  </div>
                </Zoom>
              );
            })}
        </div>
        <div className="cursesDescRight">
          {currentCurse && (
            <div dangerouslySetInnerHTML={{ __html: currentCurse.Link }}></div>
          )}
          {currentCurse && (
            <div className="descCurs">
              <h3 style={{ textAlign: "center" }}>{currentCurse.Title}</h3>
              <p>{currentCurse && currentCurse.Descr}</p>

              <div className="tas" onClick={() => setOpen(true)}>
                Приобрести
              </div>
              <div className="goBack" onClick={() => Navigate(routes.main)}>
                Вернуться назад
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
