import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { getCourses } from "../../Api";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import HeaderDesc from "../../Components/HeaderDesc";
import YouTube from "react-youtube";
import img1 from "./1.svg";
export default function Curses() {
  const [curse, setCurse] = useState([]);
  const [currentCurse, setCurrentCurse] = useState();
  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();
  useEffect(() => {
    getCourses().then((data) => {
      setCurse(data);
    });
  }, []);
  useEffect(() => {
    if (curse.length > 0) {
      setCurrentCurse(curse[0]);
    }
  }, [curse]);
  return document.body.clientWidth < 640 ? (
    <div className="curses">
      <Header></Header>
      <h3>Обучающие курсы</h3>
      <div className="cursesData">
        {curse.length > 0 &&
          curse.map((em) => {
            return (
              <div
                className="cursesEm"
                onClick={() => Navigate(routes.coursesEm + "?key=" + em.ID)}
              >
                <img src={img1} alt="" />
                <p>
                  <b>{em.Title}</b>
                  <span>{em.Price} ₽</span>
                </p>
              </div>
            );
          })}
      </div>
    </div>
  ) : (
    <div>
      <HeaderDesc></HeaderDesc>
      <div className="cursesDescBody">
        <div className="cursesData">
          {curse.length > 0 &&
            curse.map((em) => {
              return (
                <div
                  className="cursesEm"
                  onClick={() => setCurrentCurse(em)}
                  style={
                    currentCurse && em.ID == currentCurse.ID
                      ? { background: "rgba(94, 91, 247, 1)" }
                      : {}
                  }
                >
                  <img src={img1} alt="" />
                  <p>
                    <b>{em.Title}</b>
                    <span>{em.Price} ₽</span>
                  </p>
                </div>
              );
            })}
        </div>
        {open && (
          <div
            className="popDop"
            id="cls"
            onClick={(e) => {
              e.target.id == "cls" && setOpen(false);
            }}
          >
            {" "}
            <div
              className="regPP"
              dangerouslySetInnerHTML={{
                __html: currentCurse && currentCurse.Frame,
              }}
            ></div>
          </div>
        )}
        <div className="cursesDescRight">
          {currentCurse && (
            <div dangerouslySetInnerHTML={{ __html: currentCurse.Img }}></div>
          )}
          {currentCurse && (
            <div className="descCurs">
              <h3>{currentCurse.Title}</h3>
              {currentCurse.DescCard &&
                currentCurse.DescCard.split("#").map((em) => {
                  return (
                    <div className="descCurseEm">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="8"
                          stroke="#FFDB83"
                          stroke-width="2"
                        />
                        <path
                          d="M8 10L9.5 11.5L13 8"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p>{em}</p>
                    </div>
                  );
                })}{" "}
              <div className="priceEm">
                Стоимость — {currentCurse.Price} тыс. руб.
              </div>
              <div className="tas" onClick={() => setOpen(true)}>
                Приобрести
              </div>
              <div className="goBack" onClick={() => Navigate(routes.main)}>
                Вернуться назад
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
