import YouTube from "react-youtube";
import Header from "../../Components/Header";
import "./style.css";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import { useEffect, useState } from "react";
import { getCourses, getDop } from "../../Api";
export default function MaterilaEm() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = JSON.parse(urlParams.get("key"));
  const [curses, setCurses] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getDop().then((data) => {
      setCurses(data);
    });
  }, []);
  const Navigate = useNavigate();

  return (
    <div className="matsEm">
      {open && (
        <div
          className="popDop"
          id="cls"
          onClick={(e) => {
            e.target.id == "cls" && setOpen(false);
          }}
        >
          {" "}
          <div
            className="regPP"
            dangerouslySetInnerHTML={{
              __html: curses.length > 0 && curses[myParam].Frame,
            }}
          ></div>
        </div>
      )}
      <Header></Header>

      <h3>{curses.length > 0 && curses[myParam].Title}</h3>
      <Zoom>
        <div className="linkVideo">
          <div
            dangerouslySetInnerHTML={{
              __html: curses.length > 0 && curses[myParam].Link,
            }}
          ></div>
        </div>
      </Zoom>
      <div className="matDesc">
        {curses.length > 0 && curses[myParam].Descr}
      </div>
      <div
        id="scr"
        className="tas"
        onClick={() => {
          setOpen(true);
        }}
      >
        Получить
      </div>
    </div>
  );
}
