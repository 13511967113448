import Profile from "..";
import Header from "../../../Components/Header";
import HeaderDesc from "../../../Components/HeaderDesc";
import "./style.css";
import pod from "./pod.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import bl from "./bl.png";
import { useEffect, useState } from "react";
import { getReferal, login } from "../../../Api";
export default function Progress() {
  const Navigate = useNavigate();
  const [balls, setBalls] = useState(0);
  const [countRef, setCountRef] = useState(0);
  const [user] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : { ID: 1 }
  );
  useEffect(() => {
    getReferal(user.ID).then((data) => {
      setCountRef(data);
    });
  }, []);
  useEffect(() => {
    let user = JSON.parse(localStorage.user);

    login(user.Email, user.Password).then((data) => {
      let tec = data[0];

      let ballsArr = JSON.parse(tec.Bonus);

      let f = 0;
      for (let i = 0; i < ballsArr.length; i++) {
        if (ballsArr[i] == 1) {
          f++;
        }
      }
      setBalls(f * 100);
    });
  }, []);
  if (document.body.clientWidth > 640) {
    return (
      <div>
        <HeaderDesc></HeaderDesc>
        <div className="presntsDescBody">
          <Profile></Profile>
          <div className="progressBody">
            <div className="leftProgress">
              <p>ваш Прогресс</p>
              <h3>{balls + countRef * 50}</h3>
              <span>баллов</span>
              <img src={pod} alt="" />
              <div className="tas" onClick={() => Navigate(routes.presents)}>
                Обменять баллы
              </div>
              <div className="opbutt" onClick={() => Navigate(routes.main)}>
                На главную
              </div>
            </div>
            <div className="rightPr">
              <iframe
                width="720"
                height="405"
                src="https://rutube.ru/play/embed/f8fbceedeb15f0f20084d8ba08a202be/"
                frameBorder="0"
                allow="clipboard-write; autoplay"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
              <p>
                За прохождение каждого урока, за выполнение заданий дня, мы
                начисляем баллы. Поскольку мы говорим про судьбу, то это так
                называемые судьбоносные баллы. Вы зарабатываете эти баллы и
                потом идёте в пункт обмена жизненных кризисов на счастье. К
                каждому количеству баллов соответствует определенный видео урок.
                Он тоже идет вам в подарок при условии, что вы просматриваете
                все уроки тест-драйва. Так же, вы можете вступить в партнерскую
                программу.Возможно, вы пожелаете порекомендовать прохождение
                тест – драйва вашим знакомым, друзьям и близким, за это тоже
                начисляются баллы! Внимательно смотрите программу дня для того,
                чтобы понять, какие задания на день у вас есть и какие баллы
                начисляются.Все видео, которые вы сможете получить как обмен
                ваших бонусных баллов, дадут вам более ясную и глубокую картину
                по разным жизненным ситуациям. Подборка этих видео сделана также
                не случайно, поскольку в этих видео дается углубление о том, как
                мы уже на практике работаем с данным инструментом – таро «грани
                Бога»
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className="">
        <Header></Header>
        <div className="progressBody">
          <div className="leftProgress">
            <p>ваш Прогресс</p>
            <h3>{balls + countRef * 50}</h3>
            <span>баллов</span>
            <img src={pod} alt="" />
            <div className="tas" onClick={() => Navigate(routes.presents)}>
              Обменять баллы
            </div>
            <div className="opbutt" onClick={() => Navigate(routes.main)}>
              На главную
            </div>
          </div>
          <div className="rightPr">
            <iframe
              width="720"
              height="405"
              src="https://rutube.ru/play/embed/f8fbceedeb15f0f20084d8ba08a202be/"
              frameBorder="0"
              allow="clipboard-write; autoplay"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
            <p>
              За прохождение каждого урока, за выполнение заданий дня, мы
              начисляем баллы. Поскольку мы говорим про судьбу, то это так
              называемые судьбоносные баллы. Вы зарабатываете эти баллы и потом
              идёте в пункт обмена жизненных кризисов на счастье. К каждому
              количеству баллов соответствует определенный видео урок. Он тоже
              идет вам в подарок при условии, что вы просматриваете все уроки
              тест-драйва. Так же, вы можете вступить в партнерскую
              программу.Возможно, вы пожелаете порекомендовать прохождение тест
              – драйва вашим знакомым, друзьям и близким, за это тоже
              начисляются баллы! Внимательно смотрите программу дня для того,
              чтобы понять, какие задания на день у вас есть и какие баллы
              начисляются.Все видео, которые вы сможете получить как обмен ваших
              бонусных баллов, дадут вам более ясную и глубокую картину по
              разным жизненным ситуациям. Подборка этих видео сделана также не
              случайно, поскольку в этих видео дается углубление о том, как мы
              уже на практике работаем с данным инструментом – таро «грани Бога»
            </p>
          </div>
        </div>
      </div>
    );
}
