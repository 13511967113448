import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import { routes } from "../../routes";
import "./style.css";
import YouTube from "react-youtube";
import HeaderDesc from "../../Components/HeaderDesc";
export default function StarterPage() {
  const Navigate = useNavigate();
  return (
    <div className="starter">
      {document.body.clientWidth < 640 ? (
        <Header></Header>
      ) : (
        <HeaderDesc></HeaderDesc>
      )}
      <h3>
        добро <br />
        пожаловать!
      </h3>
      <iframe
        width="720"
        height="405"
        src="https://rutube.ru/play/embed/06b7b445ceaf8bcabd36e52bd36777d6/"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>
      <div
        id="scr"
        className="tas"
        onClick={() => {
          localStorage.first = "1";
          window.location.reload();
        }}
      >
        Далее
      </div>
    </div>
  );
}
