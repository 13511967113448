import Profile from "..";
import Header from "../../../Components/Header";
import HeaderDesc from "../../../Components/HeaderDesc";
import "./style.css";
export default function Anketa() {
  return document.body.clientWidth < 640 ? (
    <div className="refer">
      <Header></Header>
      <h3>Анкета</h3>
    </div>
  ) : (
    <div className="refec">
      <HeaderDesc></HeaderDesc>
      <div className="refecBody">
        <Profile></Profile>
        <div className="referRightDesc">
          <h3>Анкета</h3>
        </div>
      </div>
    </div>
  );
}
