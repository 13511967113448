import { useEffect, useState } from "react";
import Card from "../../Components/Card";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import "./style.css";
import { routes } from "../../routes";
import { Zoom } from "react-reveal";
import HeaderDesc from "../../Components/HeaderDesc";
const arrCard = [
  {
    name: "СТРАШНЫЙ СУД",
    description:
      "для решения вашего жизненного запроса пора прекратить заниматься само-судом, вспоминая, что все, что есть в нашей жизни, любые наши проявления и ситуации – все от Бога. А, значит, осуждая себя или кого-то, обвиняя себя или других – мы вступаем на путь войны с самим Богом, утрачивая Радость жизни и состояние Счастья. Спасать кого-то от чего-то – это тоже приравнивается к Осуждению Бога, ведь «не по силам не дается». Сейчас время, когда Мироздание «взвешивает», сколько ты на самом деле отдал, и сколько тебе «отвесить» и в какой «валюте». Это тот самый бумеранг, где воздается ровно то, что ты вложил. И ты будешь собирать урожай из тех энергий, которые ты посеял. Если ты сеял вокруг себя Смуту, страдания и т.д, вокруг тебя теперь материализовалась толпа людей из этих же энергий, и наоборот. Если и сейчас ты не смиришься и продолжишь роптать, умея благодарить только за то, что тебе дали, но страдая от того, что тебе что-то не дали, дальше уже Смерть во всех ее проявлениях.",
    img: "./card/1.png",
  },
  {
    name: "СМЕРТЬ",
    description:
      "Для решения вашего жизненного запроса нужно оставить тех, кто уже давно выбрал для себя смерть, и понять, что если им не нужна их собственная жизнь, то для чего вам нужна их жизнь? Может, пора вспомнить о своей жизни? Аркан «Смерть» предупреждает, что если вы сейчас не прекратите любую помощь, даже детям, тем, кто выбрал для себя Смерть, то произойдет полное обнуление ваших ресурсов, потому что вы мешаете этим людям быстрее завершить эту жизнь и покинуть этот Земной план из собственного эгоизма и страха остаться одному. Аркан говорит также о том, что вы, не реализовав сейчас то, что хотите, также обрекаете себя на Духовную смерть, т.е. потеря радости жизни и вообще ее смысла. Пробуждайтесь к жизни, пока не поздно! Пересмотрите ваши ценники, возможно, там есть жалость к другим людям, и желание заниматься благотворительностью, что по данному Аркану категорически запрещено на этом этапе вашего жизненного пути.",
    img: "./card/2.png",
  },
  {
    name: "СОЛНЦЕ",
    description:
      "Для решения вашего жизненного запроса нужен Простор: просторный офис, просторная квартира, просторная природа. Выйти из дома, и побольше быть среди людей – на тусовках, мероприятиях, конференциях – там, где людей побольше, и они встречаются по приятному поводу (свадьбы, дни рождения, празднования каких-то важных событий).Пора открывать свои социальные сети, даже если вы не профессиональный блогер, а впуская туда своих «гостей», рассказывая о том, что происходит в вашей жизни, о чем вы размышляете, какие акценты в жизни расставляете, что важного сейчас происходит в жизни, и приглашая к обмену мнениями. Не просто быть наблюдателем чужой активной жизни, а создать эту движуху в своей собственной жизни. Если вы не поймаете эту волну сейчас, то вас пространство отбросит в состояние Подвешенности, т.к. уйдут возможности для само-проявленности.",
    img: "./card/3.png",
  },
  {
    name: "ШУТ-ДУРАК",
    description:
      "Для решения вашего вопроса требуется отбросить чувство долга перед кем бы то ни было, и вспомнить одну простую мысль, что жизнь — игра. Это стадия обнуления, и если действительно хочется решить вопрос в положительную сторону, нужно освободиться от всего, что отжило: старые установки, стереотипы, ненужные вещи, старые цели и мечты, на которые уже нет энергии.Нет смысла ожидать от кого-то поддержки и понимания, потому что-то, чего хотите сейчас вы — не хочет никто, кроме вас самих, в этом и игра — с самим собой, что добавляет еще больше азарта, и, по сути, снимает ожидания и обязательства перед кем-либо. Период кардинального обновления и перезапуска, но в одиночку.",
    img: "./card/4.png",
  },
  {
    name: "ЗВЕЗДА",
    description:
      "Для решения своего жизненного запроса нужно направить свои ресурсы в сторону того, чтобы профессионально работать на «звездные компании» - компании, которые пользуются полулярностью и имеют авторитет в социуме. Спросить себя: «Своей работой я способствую их процветанию/финансовому благополучию, или, наоборот, ухудшаю?» Аркан «Звезда» указывает на то, что в информационном поле присутствует зависть и ревность, а также скрытая недобросовестная конкуренция с теми, кто выше тебя, умнее тебя, богаче тебя. Что, если с ними начать дружить? Находиться в их пространстве? На мероприятиях, организуемых этими людьми? Девиз этой карты: «Потуши свою Звезду, и служи другим звездам, это твой путь к собственной Звезде». Не сделав этого, тебя ждет эмоциональное выгорание, нервное истощение и глубокое обесценивание своих собственных ресурсов.",
    img: "./card/5.png",
  },
  {
    name: "ОТШЕЛЬНИК",
    description:
      "Для решения вашего запроса нужно уйти в глубокую медитацию и обрести соединенность с Богом. Вы сейчас в стадии глубокого разочарования в тех людях, кому вы доверили свою любовь, свою душу, свои сокровенные мечты и тайны. Травмы предательства и измены не дают двигаться дальше. Нужна профессиональная помощь в работе с ними, но, прежде чем обратиться за профессиональной помощью, задать себе вопрос: «А готов ли я вновь открыться людям, если знаю, что это может повториться?».Важно понять, что проработка какой-либо травмы не означает исключение подобных ситуаций в будущем. Проработка дает лишь инструменты, с помощью которых ты теперь по-другому будешь к этому относиться, и не зависать в обидах и чувстве несправедливости. Побудьте наедине с самим собой, вдруг вам станет с собою легко настолько, что вы перестанете из страха одиночества с кем-то впредь создавать созависимые отношения, теряя себя?",
    img: "./card/6.png",
  },
  {
    name: "УМЕРЕННОСТЬ",
    description:
      "Для решения вашего жизненного запроса нужно тщательно пересмотреть расход своих жизненных сил, времени и денег, быть честным перед собою и ответить на вопрос: «Что я получаю взамен на то, что я отдаю? Умею ли я извлекать пользу из того, что я отдаю?» Аркан указывает на присутствие в информационном поле энергии само-обесценивания, когда обесценивая свои труды, ты начинаешь обесценивать труды других людей, покупая то, чем, в итоге, не пользуешься. А свои труды мы обесцениваем по одной простой причине: потому что не вкладываем туда Любовь и Душу. Ценится только тот Труд, в котором есть Миссия, пора ее раскрывать, тогда будет понятна Миссия тех людей, чьи продукты ты покупаешь. Цени время своей жизни, и не трать его попусту, оно катастрофически идет к обнулению.",
    img: "./card/7.png",
  },
  {
    name: "ФОРТУНА",
    description:
      "Для решения вашего запроса нужно вспомнить, что удача улыбается однократно, и ее нельзя повторить. Задайте себе вопрос: «Кого вы копируете/кому вы подражаете?» Уберите это все из своей жизни, из своей внешности, отовсюду. Удачливый человек – это личность, которая не зависит от мнения социума, не копирует чью-то внешность, или чьи-то учения, а пишет свою собственную книгу жизни. Если бы вы поверили сейчас в свою удачу, то какое действие вы бы дерзнули совершить, не имея на это никаких гарантий, и даже будучи готовым что-то потерять в обмен на энергию авантюризма? Совершая такие непродуманные действия, вы развиваете свою энергию Фортуны, и она однажды вам улыбнется, но нужен Навык так жить, чтобы открылась настоящая Вера!",
    img: "./card/8.png",
  },
  {
    name: "ПАДАЮЩАЯ БАШНЯ",
    description:
      "Для решения своего жизненного запроса нужно добровольно попрощаться с хорошим мнением о самом себе со стороны тех, кто вас знает, и именно сейчас решившись делать то, за что тебя 100% осудит твое старое окружение, а нового окружения пока что нет. Задай себе вопрос: «Что такого нужно сделать, за что я 100% получу критику, непонимание и отверженность со стороны самых близких людей?» Только не обманывайте себя в том, что ваши близкие во всем вас поддерживают. Наверняка, есть то, что они не принимают, и вы это знаете, и поэтому тоже «не хотите сделать». Перестаньте служить их эгоизму, и выберите служить своему эгоизму. Поработайте над теми словами, которые вы больше всего боитесь услышать в свой адрес, и начните говорить это сами себе. Умерьте свою гордыню, и примите в себе самые темные стороны.",
    img: "./card/9.png",
  },
  {
    name: "ПОВЕШЕННЫЙ",
    description:
      "Для решения вашего жизненного запроса нужно разрешить себе брать ответственность за других только в том случае, если это люди, заточенные на развитие, а не на самоуничтожение. Метать бисер перед свиньями сейчас не время, т.к. впереди то время, когда вам самому уже скоро нужна будет помощь и уже не будет ресурсов помогать другим. Аркан «Повешенный» призывает к осознанному выбору тех людей, кому вы уделяете время своей жизни, иначе совсем скоро ваше Солнце будет проживать Закат. Берите на себя только те обязательства, в чем вы действительно профессионал по роду своей деятельности, даже если это касается родственников, и не берите на себя те обязательства, где вы не специалист.",
    img: "./card/10.png",
  },
  {
    name: "МИР",
    description:
      "Для решения вашего жизненного запроса пора принять, что все, что есть у людей – от их интерпретации Бога. Вот сколько они медитируют, наполняя себя энергией Бога, вот столько в их жизни и Радости. Прими это с Миром, и не пытайся делать так, чтобы в их жизни было ровно столько же Добра, Счастья и Любви, сколько в твоей жизни. Увидь во всем высшее проявление воли Бога, и перестань кого-то жалеть, или за кого-то страдать. Прекрати суету и панику, и с миром прими то, что есть у тебя прямо сейчас, а также возможную мысль о том, что то, о чем ты мечтаешь, может не случиться вообще, но умей продолжать мечтать, наполняя себя просто состоянием в этот момент, без дикого желания обязательно проявить это в материальном мире, но искренне радуясь за тех, у кого произошло то, о чем ты можешь только мечтать. «Мечтать без желания этим обладать» - наивысшее проявление соединенности с Богом. Твори Мир вокруг себя, и состояние у-мир-о-твор-ения придет в теле, в мыслях и в душе!",
    img: "./card/11.png",
  },
  {
    name: "ИЕРОФАНТ",
    description:
      "Для решения вашего запроса нужно обратиться к профессиональным Духовным учителям, ведущим активную творческую деятельность, потому что вы сейчас на этапе глубокого Духовного кризиса, где сама концепция – что есть Бог, и как с этим взаимодействовать – в корне ложная, и не дает духовной опоры.А именно Духовного стержня сейчас и не хватает, чтобы двигаться вперед. Задав себе вопрос – «Кто есть Я?», важно осознать свое ничтожество перед Богом, и, вместе с этим, Духовную силу, осознавая, что есть Нечто, и доверяя этому Нечто свою судьбу. Двигаться в одиночку без поддержки профессиональных Духовных наставников на данном этапе дальше не получится.",
    img: "./card/12.png",
  },
  {
    name: "СИЛА",
    description:
      "Для решения вашего запроса не хватает телесной близости с любящими вас людьми. Если вдруг таковых людей нет, чьи объятия вам приятны, есть смысл задать себе вопрос: «Как так случилось, что нет того, в чьих объятиях я обретаю покой?». Присутствие эмоционального холода блокирует развитие ситуации к лучшему. Вы привыкли двигаться к целям через психологическое насилие над собой, через преодоление и подавление своих настоящих желаний. Дальше так не пойдет, тело истощено, нервы на пределе, и депрессия не за горами. Все близкие люди от вас уже бегут, т.к. с вами стало невозможно сосуществовать, ваш гиперконтроль блокирует их личную свободу и самовыражение. Если сейчас вовремя не остановиться и не задуматься, то дальше – серьезная болезнь, и тогда вам придется научиться мирно со-существовать с другими, чтобы они захотели за вами ухаживать с ложный период вашей жизни.",
    img: "./card/13.png",
  },
  {
    name: "ЛУНА",
    description:
      "Для решения вашего жизненного запроса нужно направить свои ресурсы в сторону уравновешивания своей эмоциональной составляющей. Наступает закат, после которого обязательно придет рассвет. Настало время попрощаться с очень важным периодом своей жизни, а также с теми людьми, кто эмоционально обнуляет, не верит в вас, постоянно психологически продавливает и подчиняет. Напитывайтесь общением с маленькими детьми, домашними хлопотами и обустройством своего уголка, делая его уютным гнездышком. Причина того, что как будто бы ничего не движется в том, что вам не нравится жилье, в котором вы живете, либо не нравятся домочадцы, с которыми вы разделяете свою территорию. Либо это жилье пора менять, либо обустроить его так, чтобы оно нравилось, включая соседей. Важно выбирать тихое спокойное место, чтобы было минимум шума и резких звуков.",
    img: "./card/14.png",
  },
  {
    name: "ВЕРХОВНАЯ ЖРИЦА",
    description:
      "Настало время спросить себя: «А точно ли у меня достаточно академических знаний, чтобы двигаться в сторону решения своего вопроса?». Аркан показывает на профессиональную некомпетентность, абсолютную несамостоятельность и безответственность, и настало время обучаться у лучших экспертов рынка и брать профессиональную консультацию у них, чтобы качественно решать свои вопросы.Позиция «я сам все знаю» привела к психологическому дну, накопленным и нерешенным вопросам, которые висят как груз. Обратитесь за профессиональным советом в той сфере, из которой у вас запрос. Также Аркан показывает, что у вас есть тенденция спрашивать совета у «незнающих» - псевдоспециалистов, «самозванцев», каковым, возможно, являетесь и вы сами.Юридические вопросы решает профессиональный юрист, бизнес-вопросы решает бизнес-консалтинг, вопросы здоровья решает профессиональный врач, и т.д. Это не время обращаться за помощью к целителям или психологом, если только вопрос не касается психологического состояния.",
    img: "./card/15.png",
  },
  {
    name: "МАГ",
    description:
      "Для решения вашего жизненного запроса нужно вспомнить о том, что настоящий Маг – это тот, кто больше делает, чем говорит и рассуждает. Твоя волшебная палочка в руках – это конкретные действия, а не мысли или чувства о действиях.Прекрати ходить к магам и экстрасенсам для решения конкретных материальных запросов, начинай просто делать, и это и будет магия в твоей жизни. Чудеса происходят там, где есть действия от веры в те самые чудеса, а не, наоборот, бездействие от веры в чудеса.",
    img: "./card/16.png",
  },
  {
    name: "ПРАВОСУДИЕ",
    description:
      "Для решения вашего запроса нужно перестать быть Судьей своих родителей, помня, что то, что мы осуждаем в своих родителях, странным образом впечатывается в нашу собственную жизнь, по причине чего мы утрачиваем все, что создаем большим трудом. Впереди настоящие судебно-правовые процессы, с законодательной точки зрения, если не разобраться с осуждением своего рода. И именно страх судебно-правовых процессов, по факту, и блокирует развитие вашей жизненной ситуации или совершения каких-то изменений, необходимость в которых уже давно созрела. А пока в вашей жизни и царит беззаконие – мошенничество, обманы, подставы, и этим людям «ничего не делается», потому что источник всех этих людей в вашей жизни – это ваши отношения с собственными родителями. Если вы сами явились инициатором каких-либо судебных процессов, завершите эти дела, и заберите иски, если не хотите ухудшения своих жизненных ситуаций",
    img: "./card/17.png",
  },
  {
    name: "ДЬЯВОЛ",
    description:
      "Для решения вашего жизненного запроса требуется увидеть само-обманы и иллюзии, где постоянно идет привычка выдавать желаемое за действительное. Чем больше человек бежит от истины, тем выше вероятности, что на его пути все время будут встречаться люди, обманывающие его по всем фронтам, где даже профессионалы будут ставить тебе ошибочный диагноз, потому что ты решил служить Злу, а не Добру. Служить злу – это уходить от своих собственных целей и Жизни своей Мечты, тем самым все больше и больше злясь на самого себя за трусость и нерешительность. Служить Добру – это каждый день соотносить свои действия со своими целями, а не целями других людей. Если ты продолжишь обманывать себя, своего партнера, свое окружение, называя свой страх потери банальным словом «Любовь», дальше полный крах во всех сферах жизни, и потеря своего Духовного пути.",
    img: "./card/18.png",
  },
  {
    name: "КОЛЕСНИЦА",
    description:
      "Для решения вашего запроса нужно разрешить себе Эмоции, сделав их главной движущей силой своего пути. Если это негативные Эмоции, значит, разрешить их себе выплескивать, будучи готовым к любым последствиям, вплоть до потери работы, отношений с кем бы то ни было, и т.д, но именно такой эмоциональный разрыв поможет продвинуться быстро и стремительно к поставленным целям.Если есть зажатость в эмоциях, рекомендовано использовать Гвоздестояние, духовно-телесные практики, дыхательные практики, чтобы их разжечь, и только этот огонь – либо Радость, либо яркий Гнев – помогут продвинуть ситуацию и ее разрешить.",
    img: "./card/19.png",
  },
  {
    name: "ИМПЕРАТРИЦА",
    description:
      "Для решения вашего запроса нужно перестать рассчитывать на мужчину в принципе, даже если вы мужчина, и обратиться к природной женской составляющей, женщине-матери. Пока не будут урегулированы вопросы с принятием собственной матери и себя как матери в целом, или матери своих детей, вопрос продолжит оставаться нерешенным, заводя в тупик все дальше и дальше. Аркан «Императрица» требует раскрытия в себе социального материнства, увидев в этом свою миссию, и служить тем, кто ниже тебя, беднее тебя, менее умный, чем ты, и извлекать из этого материальную выгоду, не делая из себя жертву.",
    img: "./card/20.png",
  },
  {
    name: "ВЛЮБЛЕННЫЕ",
    description:
      "Для решения вашего запроса нужно увидеть в себе эгоизм и само-влюбленность, задав себе вопрос: «Во что я влюблен в самом себе?».Иногда это бывает самолюбование своей жертвой, самобичевание, негативное самоопределение, самолюбование своими болезнями и кризисами (то, о чем мы говорим чаще всего, это нам больше всего и нравится в самом себе).Иногда это бывает влюбленность в свое прошлое, и зависание там. Иногда это бывает влюбленность в свои достижения и этапы своего успеха.Иногда это бывает влюбленность в чувство нелюбви к самому себе. Аркан говорит о том, что то, чем ты «любуешься» в самом себе, это ты будешь ненавидеть в других, и отвергать, но при этом, создавая «единство противоположностей» через созависимые отношения с полной противоположностью себя. Вырваться из этого круга поможет развитие прямо противоположных качеств в себе, и умение любоваться тем, что стоит по другую сторону медали.",
    img: "./card/21.png",
  },
  {
    name: "ИМПЕРАТОР",
    description:
      "Для решения вашего запроса нужно перестать рассчитывать на женщин как таковых, даже если вы женщина, и раскрывать свое мужское «Я» в виде таких качеств как напористость, ответственность, принятие самостоятельных решений, дерзость, воинственность, умение идти по головам, если надо, для достижения своих целей, но в рамках соблюдения законов судебно-правовой системы.",
    img: "./card/22.png",
  },
];
export default function CardChoose() {
  const [cards, setCards] = useState([]);
  const [currentCards, setCurrentCards] = useState([]);
  const Navigate = useNavigate();
  const shuffle = (array) => {
    let m = array.length,
      t,
      i;

    // Пока есть элементы для перемешивания
    while (m) {
      // Взять оставшийся элемент
      i = Math.floor(Math.random() * m--);

      // И поменять его местами с текущим элементом
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  };
  function clear() {
    let sortedNumbers = [...cards];
    sortedNumbers = shuffle(sortedNumbers);
    setCards(sortedNumbers);
    setCurrentCards([]);
  }
  useEffect(() => {
    setCards(shuffle(arrCard));
  }, []);
  function changer() {
    if (document.body.clientWidth < 640) {
      let arrCd = document.getElementsByClassName("card");
      for (let i = 0; i < arrCd.length; i++) {
        if (i < 12) {
          arrCd[i].style.left = -16 * i + "vw";
          setTimeout(() => {
            arrCd[i].style.left = -10 * i + "vw";
          }, 500);
        } else {
          arrCd[i].style.left = -16 * (i - 12) + "vw";
          setTimeout(() => {
            arrCd[i].style.left = -10 * (i - 12) + "vw";
          }, 500);
        }
      }
    } else {
      let arrCd = document.getElementsByClassName("card");
      for (let i = 0; i < arrCd.length; i++) {
        arrCd[i].style.left = -8 * i + "vw";
        arrCd[i].style.transform = "rotateZ(" + 10 * i + "deg)";
        setTimeout(() => {
          arrCd[i].style.left = -4 * i + "vw";
          arrCd[i].style.transform = "rotateZ(0deg)";
        }, 500);
      }
    }
  }
  return document.body.clientWidth < 640 ? (
    <div className="CardChooseBefore">
      <div className="chooseBeforeBody">
        <Header></Header>
        <Zoom>
          <h2>
            Выберите <br />
            <span> карту</span>
          </h2>
        </Zoom>
        <div className="cards cd1">
          {cards &&
            cards.map((em, index) => {
              if (index < 12) {
                return (
                  <Card
                    setCurrentCards={setCurrentCards}
                    options={em}
                    lft={index}
                    key={"card" + index}
                    cards={cards}
                  ></Card>
                );
              }
            })}
        </div>
        <div className="cards cd2">
          {cards &&
            cards.map((em, index) => {
              if (index >= 12) {
                return (
                  <Card
                    setCurrentCards={setCurrentCards}
                    options={em}
                    lft={index - 12}
                    key={"card2" + index}
                    currentCards={currentCards}
                    cards={cards}
                  ></Card>
                );
              }
            })}
        </div>

        <div
          className="tas"
          onClick={() => {
            setCards([]);
            clear();
            changer();
          }}
        >
          Перетасовать
        </div>
      </div>
      <div className="desc">
        {currentCards.length > 0 &&
          currentCards.map((em, index) => {
            return (
              <div className="cardDesc">
                <img src={em.img} alt="" />
                <p>{em.name}</p>
                <span>{em.description}</span>
              </div>
            );
          })}
      </div>
      {currentCards && currentCards.length > 0 && (
        <div
          id="scr"
          className="tas"
          onClick={() => {
            Navigate(routes.vebinar);
          }}
        >
          Далее
        </div>
      )}
    </div>
  ) : (
    <div className="chooseDesc">
      <HeaderDesc></HeaderDesc>
      <h3>
        <span>Выберите</span> <p>карту</p>
      </h3>
      <div className="cards cd1">
        {cards &&
          cards.map((em, index) => {
            return (
              <Card
                setCurrentCards={setCurrentCards}
                options={em}
                isDesc={true}
                lft={index}
                key={"card" + index}
                cards={cards}
              ></Card>
            );
          })}
      </div>
      <div style={{ display: "flex" }} className="bts">
        <div className="backs" onClick={() => window.history.back()}>
          Вернуться назад
        </div>
        <div
          className="tas"
          onClick={() => {
            setCards([]);
            clear();
            changer();
          }}
        >
          Перетасовать
        </div>
      </div>
      <div className="desc">
        {currentCards.length > 0 &&
          currentCards.map((em, index) => {
            return (
              <div className="cardDesc">
                <img src={em.img} alt="" />
                <div>
                  <p>{em.name}</p>
                  <span>{em.description}</span>
                </div>
              </div>
            );
          })}
      </div>
      {currentCards && currentCards.length > 0 && (
        <div
          id="scr"
          className="tas"
          onClick={() => {
            Navigate(routes.vebinar);
          }}
        >
          Далее
        </div>
      )}
    </div>
  );
}
