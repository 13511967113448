import Header from "../../Components/Header";
import "./style.css";
import i1 from "./img/1.png";
import i2 from "./img/2.png";
import i3 from "./img/3.png";
import i4 from "./img/4.png";
import i5 from "./img/5.png";
import i6 from "./img/6.png";
import { routes } from "../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
export default function Profile() {
  const Navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="profile">
      {document.body.clientWidth < 640 && <Header></Header>}
      {document.body.clientWidth < 640 && <h3>личный кабинет</h3>}
      {document.body.clientWidth < 640 && (
        <div className="tas">
          <h4>Задание дня:</h4>
          <p>Зарегистрироваться в партнерской программе</p>
        </div>
      )}
      <Fade left cascade>
        <div
          className="profileEm"
          onClick={() => Navigate(routes.profileProgress)}
        >
          <img src={i1} alt="" />
          <p>Прогресс</p>
        </div>

        <div className="profileEm" onClick={() => Navigate(routes.profileData)}>
          <img src={i2} alt="" />
          <p>Личный профиль</p>
        </div>
        <div
          className="profileEm"
          onClick={() => Navigate(routes.referal)}
          style={
            location.pathname == routes.referal ? { background: "#5E5BF7" } : {}
          }
        >
          <img src={i3} alt="" />
          <p>Рефералы</p>
        </div>
        <div className="profileEm" onClick={() => Navigate(routes.partner)}>
          <img src={i4} alt="" />
          <p>Партнерка</p>
        </div>
        <div
          className="profileEm"
          onClick={() =>
            window.open(
              "https://app.getreview.io/campaign/5T3r3u5lasSf9aAG",
              "_blank"
            )
          }
        >
          <img src={i5} alt="" />
          <p>Записать отзыв</p>
        </div>
        <div
          className="profileEm"
          style={
            location.pathname == routes.presents
              ? { background: "#5E5BF7" }
              : {}
          }
          onClick={() => Navigate(routes.presents)}
        >
          <img src={i6} alt="" />
          <p>Мои подарки</p>
        </div>
      </Fade>
    </div>
  );
}
