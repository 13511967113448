import YouTube from "react-youtube";
import Header from "../../../Components/Header";
import "./style.css";
import { Fade } from "react-reveal";
import HeaderDesc from "../../../Components/HeaderDesc";
import Profile from "../index";
import pod from "./pod.png";
export default function Presents() {
  return document.body.clientWidth < 640 ? (
    <div className="presents">
      <Header></Header>
      <h3>Мои подарки</h3>
      {[1, 1, 1, 1, 1, 1, 1, 1, 1].map((em, index) => {
        return (
          <div>
            <iframe
              style={{ pointerEvents: "none", opacity: "0.5" }}
              width="720"
              height="405"
              src="https://rutube.ru/play/embed/f8fbceedeb15f0f20084d8ba08a202be/"
              frameBorder="0"
              allow="clipboard-write; autoplay"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
            <p>Название подарка</p>
          </div>
        );
      })}
    </div>
  ) : (
    <div>
      <HeaderDesc></HeaderDesc>
      <div className="presntsDescBody">
        <Profile></Profile>
        <div className="presentsBodyRight">
          {[1, 1, 1, 1, 1, 1, 1, 1, 1].map((em, index) => {
            return (
              <div>
                <iframe
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  width="720"
                  height="405"
                  src="https://rutube.ru/play/embed/f8fbceedeb15f0f20084d8ba08a202be/"
                  frameBorder="0"
                  allow="clipboard-write; autoplay"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
                <p>Название подарка</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
